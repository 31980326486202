.App {
  text-align: center;
  overflow-x: hidden;
  display: block;
}

.content {
  overflow: hidden;
  background: #f2f2f2;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.nav-text {
  font-weight: bold;
}

.active {
  color: #ffffff;
}

.active:hover {
  color: #d1d1d1;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: #f1f1f1;
}

.flex-container > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  float: left;
  text-align: justify;
}

.text-title {
  font-size: 2vw;
  font-family: bold;
}

.text-description {
  font-size: 1.5vw;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.color {
  width: 100%;
  height: 100px;
  background-color: #26004c;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  color: white;
  font-family: bold;
  font-size: 25px;
}

.image-container {
  position: relative;
}

.container-erreur {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content-erreur {
  position: relative;
  flex: auto;
  height: auto;
  color: white;
  font: bold;
  font-size: calc(10px + 10vmin);
  overflow: scroll;
}
.content-block-erreur {
  color: white;
  position: absolute;
  width: 70%;
  background-color: #282c3480;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-404 {
  font-size: calc(10px + 12vmin);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.text-oups {
  font-size: calc(10px + 5vmin);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.text-block-erreur {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
