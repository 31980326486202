.form-contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

input {
  width: 100%;
  margin-top: 30px;
  padding: 5px;
}

.button-send-form {
  border-radius: 0;
  min-width: 200px;
  min-height: 50px;
  margin-top: 30px;
  margin-bottom: 10px;
  width: auto;
  background: #04b8cf;
  color: white;
  border-radius: 50px;
  border-color: white;
}
.button-send-form:hover {
  background: white;
  color: #04b8cf;
  border-color: #04b8cf;
}
